import React, {useEffect, useContext} from "react";
import { AuthState } from "@aws-amplify/ui-components";
import {AmplifyAuthenticator, AmplifyForgotPassword, AmplifySignIn, AmplifySignUp} from '@aws-amplify/ui-react';
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../contexts/Auth";

const useStyles = makeStyles({
  authContainer: {
    backgroundImage: "url(/office-team.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  amplifyAuthenticator: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  amplifySignUp: {

  }
})

const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

function Authentication(props) {
  const history = useHistory();
  const {user, authState, handleSignUp} = useContext(AuthContext);
  const classes = useStyles();
  const queryParams = getQueryParams();


  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      history.push('/admin')
    }
  }, [history, authState])

  if (props.authState === 'signup' && !queryParams['customerId'] && !queryParams['productCode']) {
    history.push('/')
  }

  if (user && user.username) {
    // user is logged in
    history.push('/admin')
  }

  return (
    <div className={classes.authContainer}>
      <AmplifyAuthenticator className={classes.amplifyAuthenticator} usernameAlias="email" initialAuthState={props.authState}>
        <AmplifySignIn
          slot="sign-in"
          hideSignUp={true}
          usernameAlias="email"
          headerText="Sign in to your Digital Space account"
        />

        <AmplifyForgotPassword
          slot="forgot-password"
          usernameAlias="email"
        />

        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          handleSignUp={(formData) => handleSignUp(queryParams, formData)}
          className={classes.amplifySignUp}
          formFields={[
            {
              required: true,
              type: "name",
              label: "Full name",
              placeholder: "Firstname Lastname",
              inputProps: { required: true, autocomplete: "name" },
            },
            {
              required: true,
              type: "email",
              label: "Email",
              placeholder: "firstname.lastname@company.com",
              inputProps: { required: true, autocomplete: "email" },
            },
            // {
            //   required: true,
            //   type: "text",
            //   label: "Company",
            //   placeholder: "Example LTD",
            //   inputProps: { required: true, autocomplete: "company" },
            // },
            {
              required: true,
              type: "phone_number",
              label: "Phone Number",
              placeholder: "Phone",
              inputProps: { required: true, autocomplete: "phone" }
            },
            {
              required: true,
              type: "website",
              label: "Website",
              placeholder: "https://...",
              inputProps: { required: true, autocomplete: "website" },
            },
            // {
            //   required: true,
            //   type: "custom:awsAccountId",
            //   label: "AWS Account ID",
            //   placeholder: "Account Number",
            //   custom: true,
            //   inputProps: { required: true },
            // },
            {
              required: true,
              type: "password",
              label: "Password",
              placeholder: "Enter a secure password",
              inputProps: { required: true, autocomplete: "password" },
            },
          ]}
        />
      </AmplifyAuthenticator>
    </div>
  )
}

export default Authentication;
