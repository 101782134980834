/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "client",
            "endpoint": "https://yhgo1tmxn9.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "access",
            "endpoint": "https://4dhqfo4nl8.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://3fylmcqxhfb2pox5xgjze4h5cy.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-puhn3llk25ahxe5wofmdxb2qfe",
    "aws_cognito_identity_pool_id": "eu-west-2:5d8b9731-a865-4a54-809d-f4531068b448",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_1XGK0gWl1",
    "aws_user_pools_web_client_id": "5rk78hgg6efve8kssscelf4eba",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER",
        "WEBSITE"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
