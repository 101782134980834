import React from "react";
import {makeStyles} from "@material-ui/core";
import Header from "./Header";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {

  },
}))

const Livechat = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />

      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <h1>
              Welcome to Livechat
            </h1>
            <h2>Overview</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nibh dolor, pretium interdum elit a, rhoncus rutrum arcu. Pellentesque eget pulvinar est. Ut at ultricies est, vel tristique magna. Maecenas dignissim eros ex, id pharetra turpis vestibulum vel. Curabitur sollicitudin urna non mi varius cursus. Mauris condimentum lorem nec tortor molestie, in venenatis felis luctus. Aliquam scelerisque elementum hendrerit. Donec dictum felis urna, eu cursus purus faucibus nec. Nulla at auctor mauris. Sed faucibus ipsum iaculis, scelerisque nulla quis, ultrices ex.
            </p>
            <p>
              Ut accumsan, nibh sit amet lacinia feugiat, tortor orci sodales sapien, at sodales erat nulla cursus turpis. Morbi mattis, augue sed fringilla hendrerit, metus diam luctus augue, eget bibendum orci dui nec velit. Maecenas in viverra nibh. Suspendisse placerat bibendum magna, eget posuere augue mattis eget. In hac habitasse platea dictumst. Ut imperdiet ipsum in justo molestie mattis. Cras a felis ac ligula elementum aliquam tempor a nisl. Donec facilisis diam augue, nec dignissim massa feugiat vitae. Vestibulum auctor, turpis non finibus molestie, est mi viverra ligula, id fringilla sem urna at tortor. Aenean non nibh sollicitudin, condimentum lacus consectetur, scelerisque ipsum. Aenean elementum blandit scelerisque. Cras non odio metus. In nec fermentum tortor. Nulla vulputate, ex eu volutpat rhoncus, tellus sem tempus lectus, non accumsan dolor elit vitae dolor. Mauris ullamcorper nec tellus nec ornare.
            </p>
            <h2>Features</h2>
            <ul>
              <li>Praesent fringilla erat ac mattis tincidunt. Nulla id vehicula neque.</li>
              <li>Maecenas ac risus eu quam elementum imperdiet non nec elit.</li>
              <li>Aliquam vel magna dapibus, lacinia eros non, imperdiet lectus.</li>
              <li>Morbi nisl arcu, cursus sit amet risus fermentum, lobortis facilisis velit.</li>
              <li>Ut commodo mattis erat, semper finibus arcu sollicitudin sit amet. </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Livechat;
