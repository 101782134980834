import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core";
import {AuthContext} from "../contexts/Auth";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Star from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  adminContainer: {

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sidePanel: {
    minWidth: '320px',
  },
  sidePanelHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '0 16px',
  },
  sidePanelHeaderLogo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',

    '& img': {
      height: '25px',
      marginRight: '10px',
    }
  },
  title: {
    flexGrow: 1,
  },
  titleLogo: {
    marginRight: theme.spacing(2.5),

    '& img': {

    }
  },
  signInOutButton: {

  },
}))


const Header = () => {
  const classes = useStyles();
  const {user} = useContext(AuthContext);

  console.log('user', user)

  const loggedIn = user && user.username;

  const [sidePanelState, setSidePanelState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setSidePanelState(open);
  };

  const handleSignOutButtonClick = async (e) => {
    e.preventDefault();

    try {
      await Auth.signOut();

      Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
        event: 'AuthStateChange',    // event must be 'AuthStateChange'
        message: 'signedout'    // message must be 'signedout'
      });

    } catch (error) {
      console.log('error signing out: ', error);
    }

  }

  return (
    <>
      <Drawer anchor={'left'} open={sidePanelState} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          className={classes.sidePanel}
        >
          <div className={classes.sidePanelHeader}>
            <div className={classes.sidePanelHeaderLogo}>
              <img
                src="/images/digital-space-32x28.png"
                title="Digital Space Logo"
                alt="Digital Space Logo"
              />
              Digital Space
            </div>
            <div>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <List>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <ListItemText primary="Documentation" />
            </ListItem>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <ListItemText primary="Support" />
            </ListItem>
          </List>
        </div>
      </Drawer>

      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.titleLogo}>
            <img
              src="/images/digital-space-32x28.png"
              title="Digital Space Logo"
              alt="Digital Space Logo"
            />
          </div>

          <Typography variant="h6" className={classes.title}>
            Digital Space Admin Panel
          </Typography>

          <div className={classes.signInOutButton}>
            {loggedIn &&
              // <AmplifySignOut />
              <Button variant="contained" disableElevation onClick={handleSignOutButtonClick}>
                Sign out
              </Button>
            }

            {!loggedIn &&
              <Button component={Link} to="/login" variant="contained" disableElevation>
                Login
              </Button>
            }
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header;
