
import Amplify, { API, Auth } from "aws-amplify";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import awsConfig from './aws-exports';

Amplify.configure(awsConfig);
API.configure(awsConfig);
Auth.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
