import React, {createContext, useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";

export const AuthContext = createContext({});

export default function AuthProvider({children}) {
  const [user, setUser] = useState();
  const [authState, setAuthState] = useState();

  useEffect(() => {
    (async () => {
      if (!user) {
        try {
          // will throw an exception if the user is not signed in
          const user = await Auth.currentAuthenticatedUser();
          setUser(user)
        } catch(err) {}
      }
    })();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, nextAuthData) => {
      setAuthState(nextAuthState);
      setUser(nextAuthData);
    });
  }, []);

  const handleSignUp = async (queryParams, formData) => {
    return await API.post('client', '/save', {
      queryStringParameters: {
        customerId: queryParams['customerId'],
        productCode: queryParams['productCode'],
        registrationToken: queryParams['registrationToken'],
      },
      headers: {},
      body: {
        ...formData,
        attributes: {
          ...formData.attributes,
        }
      },
    })
  }

  return (
      <AuthContext.Provider
          value={{
            user,
            setUser,
            authState,
            setAuthState,
            handleSignUp,
          }}
      >
          {children}
      </AuthContext.Provider>
  );
}
