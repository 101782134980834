import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthProvider from "./contexts/Auth";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/styles";
import {createTheme} from "./theme";
import Authentication from "./components/Authentication";
import Admin from "./components/Admin";
import './App.css';
import Livechat from "./components/Livechat";
import Main from "./components/Main";

function App() {
  return (
    <CssBaseline>
      <ThemeProvider theme={createTheme({})}>
        <AuthProvider>
          <Router>
              <Switch>
                  <Route exact path="/">
                      <Main />
                  </Route>
                  <Route exact path="/livechat">
                    <Livechat />
                  </Route>
                  <Route exact path="/admin">
                    <Admin />
                  </Route>
                  <Route exact path="/register">
                    <Authentication authState='signup' />
                  </Route>
                  <Route exact path="/login">
                    <Authentication />
                  </Route>
              </Switch>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </CssBaseline>
  );
}

export default App;
